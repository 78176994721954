<template>
  <div class="projectnews">
     <ul class="newslist">
       <li v-for="(item) in newslist" :key="item.id" @click="goDetail(item.id)">
         <dt>
           <img style="width:100%; height:100%" :src="item.smallImage" alt="">
         </dt>
         <dd>
           <h2>{{item.title}}</h2>
           <!-- <p>
             中国红十字会顺应社会需要、践行自身宗旨、接受政府委托、
             依据法律授权逐步参与遗体和人体器官捐赠，作出了积极的贡献。
             2007年3月国务院《人体器官移植条例》颁布，2010年3月中国红十字会
            </p> -->
            <p>{{item.metaDescription}}</p>
         </dd>
       </li>
     </ul>
      <el-pagination
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
  </div>
</template>

<script>
import apiUrls from '@api'
export default {
  props: {
    newslist : {
      type: Array,
      default: () => []
    }
  },
  name: 'Projectnews',
  data () {
    return {
      newslist2 : [],
      page: 1,
      size: 10,
      total: 0
    }
  },
  computed: {
    
  },
  created () {
    this.handleCurrentChange()
  },
  methods: {
    handleSizeChange(val) {

    },
    handleCurrentChange(val) {
      apiUrls.selfDynamicList({node: 'rdxm_xwlb', page: val===1?0:val}).then(res=>{
      this.newslist2= res.results.data
      this.total = res.results.pager.totalElements
      this.$emit('childByValue', this.newslist2)
      })
    },
    goDetail(id){
      this.$router.push({path:'/detailShow',query:{id,isDonate:true}})
    }
  }
}
</script>

<style lang="less" scoped>
.projectnews {
    min-height: 808px;
    background-color: #ffffff;
    // margin-top: 40px;
    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
    padding: 0 12px;
    padding-top: 26px;
    padding-bottom: 30px;
    .newslist {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
		  &:hover {
		  		  transform: translateY(-5px);
		  		  box-shadow: 5px 5px 5px #ccc;
		  }
        width: 578px;
        height: 218px; 
        box-shadow: 0px 0px 10px 0px rgba(58, 58, 58, 0.24);
        border-radius: 8px;
        display: flex;
        margin-bottom: 18px;
        dt {
          width: 256px;
          height: 200px;
          background-color: #666666;
          margin: 8px 14px 10px 10px;
        }
        dd {
          width: 287px;
          height: 200px;
          // border: 1px solid #666666;
          h2 {
            text-align: center;
            font-size: 18px;
            font-weight: bolder;
            color: #D72829;
            margin-top: 26px;
            margin-bottom: 23px;
          }
          p {
            width: 287px;
            height: 129px;
            font-size: 16px;
            color: #666666;
            line-height: 26px;
            text-indent: 2em;
            overflow: hidden;
          }
        }
      }
    }
}
</style>
<style>
.el-pagination {
    min-width: 616px;
    margin: 0 auto;
    text-align: center;
    margin-top: 14px;
}
.el-pager li.active {
    color: #D1292E !important;
 }
 .el-pager li:hover {
   color: #D1292E !important;
 }
</style>
