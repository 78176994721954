import { render, staticRenderFns } from "./projectnews.vue?vue&type=template&id=c56c6b54&scoped=true&"
import script from "./projectnews.vue?vue&type=script&lang=js&"
export * from "./projectnews.vue?vue&type=script&lang=js&"
import style0 from "./projectnews.vue?vue&type=style&index=0&id=c56c6b54&lang=less&scoped=true&"
import style1 from "./projectnews.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c56c6b54",
  null
  
)

export default component.exports